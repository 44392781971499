<script>
	import Layout from "@/router/layouts/main";
	import appConfig from "@/app.config";
	import PageHeader from "@/components/page-header";
	import axios from "axios";
	import Swal from "sweetalert2"
	/**
	 * Dashboard Component
	 */
	export default {
		page: {
			title: "Konfigurasi Form Perizinan Data Teknis",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],

		},
		components: {
			Layout,
			PageHeader,
		},
		data() {
			return {
				title: "Konfigurasi Form Perizinan Data Teknis",
				items: [
					{
						text: "Master",
						href: "/",
					},
					{
						text: "Konfigurasi Form Perizinan Data Teknis",
						active: true,
					},
				],
				// 

				axiosCatchError: null,
				// Componen v-select
				select_option_field: [
					'Text Input',
					'Tanggal',
					'Textarea',
					'Select Dynamic',
					'Select Static',
					'Table Dynamic',
					// 'Table Static',
				],
				select_option_field_sub: [
					'Text Input',
					'Tanggal',
					'Textarea',
					'Select Dynamic',
					'Select Static',
				],
				// Componen Konfig
				mps_id: this.$route.params.mps_id,
				data_perizinan: [],
				row_konfig: [],
				list_table: [],
				structure_table: [],
				is_lanjut : false,
				select_dinamis : [],
				select_statis : [],
				table_dinamis : [],
				table_statis : [],
				row_konfig_lanjutan : {
					select_dinamis : [],
					select_statis : [],
					table_dinamis : [],
					table_statis : [],
				}
			};
		},
		mounted() {
			// Load data awal banget
			let self = this;

			Swal.fire({
				title: '<i class="fas fa-spinner fa-spin"></i>',
				text: "Loading...",
				showConfirmButton: false,
			});
			
			// get data berdasarkan id
			axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-konfigurasi-form", {
				params:{
					mps_id: self.mps_id
				},
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.access_token,
				},
			}).then(function (response) {
				console.log(response);
				var response_data = response.data;
				var response_data_fix = response_data.data[0];
				if(response_data.meta.code == 200){
					self.data_perizinan = response_data_fix;
					self.row_konfig = response_data_fix.konfigurasiperizinan;
					console.log(self.row_konfig);   
					Swal.close();
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: response_data_fix.data.message
					});
				}
			}).catch(e => {
				this.axiosCatchError = e.response.data.data
				Swal.close()
			})

		},
		methods:{
			addRowKonfig(){
			
				this.row_konfig.push({
					konfigurasiperizinanmeta: null,
					mkp_catatan: null,
					mkp_id: null,
					mkp_is_data_umum: null,
					mkp_is_required: null,
					mkp_label_field: null,
					mkp_mp_id: null,
					mkp_mps_id: null,
					mkp_mps_json: null,
					mkp_nama_field: null,
					mkp_tipe_field: null,
					mkp_urutan: null,
					status: null,
				});
			
			},
			removeKonfig(key_deleted){
				this.row_konfig.splice(key_deleted,  1)
			},
			StoreDataKonfig(){
				let self = this;
				Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				axios.post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/store-konfigurasi-form", {
						perizinan_sub_id: self.data_perizinan.mps_id,
						row_konfig: JSON.stringify(self.row_konfig)
					},
					{headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.access_token,
					}
				})
				.then(function (response) {
					self.is_lanjut = response.data.data.lanjut;
					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: response.data.data.message,
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
							// self.$router.push({ name: "all-sub-perizinan" });
							if(response.data.data.lanjut){
								self.loadKonfigLanjutan();
							}
							else{
								self.$router.push({ name: "all-sub-perizinan" });
							}
						}
					});
				}).catch(e => {
					self.axiosCatchError = e.response.data.data
					Swal.close();
				})
			},
			loadKonfigLanjutan(){
				let self = this;
				// Get Table Structure DB
				axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-table", {
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.access_token,
					},
				}).then(function (response) {
					var response_data = response.data;
					var response_data_fix = response_data.data;
					if(response_data.meta.code == 200){
						self.list_table = response_data_fix.referensi
					}else{
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: response_data_fix.data.message
						});
					}
				}).catch(e => {
					this.axiosCatchError = e.response.data.data
					Swal.close()
				});
				axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-konfigurasi-form-lanjutan", {
					params:{
						mps_id: self.mps_id
					},
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.access_token,
					},
				}).then(function (response) {
					var response_data = response.data;
					var response_data_fix_lanjutan = response_data.data;
					console.log(response_data_fix_lanjutan);
					if(response_data.meta.code == 200){
						self.row_konfig_lanjutan = response_data_fix_lanjutan;
					}else{
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: response_data_fix_lanjutan.data.message
						});
					}
				}).catch(e => {
					console.log(e);
					this.axiosCatchError = e.response.data.data
					Swal.close()
				})
			},
			addRowSelectStatic(key_select_statis){
				let self = this;
				var sub_select_statis = self.row_konfig_lanjutan.select_statis[key_select_statis];
				if(!sub_select_statis?.meta){
					sub_select_statis.meta = [];
				}
				console.log(sub_select_statis)
				sub_select_statis.meta.push({
					pilihan : null,
					value : null,
				});
			},
			removeRowSelectStatic(key_select_statis, key_deleted){
				let self = this;
				var sub_select_statis = self.row_konfig_lanjutan.select_statis[key_select_statis];
				sub_select_statis.meta.splice(key_deleted,  1)
			},
			addRowTableDinamis(key_table_dinamis){
				let self = this;
				var sub_table_dinamis = self.row_konfig_lanjutan.table_dinamis[key_table_dinamis];
				if(!sub_table_dinamis?.meta){
					sub_table_dinamis.meta = [];
				}
				console.log(sub_table_dinamis)
				sub_table_dinamis.meta.push({
					label : null,
					sub_meta : {
						table : [],
						pilihan : null,
						value : null,
						select_static: [{
							pilihan : null,
						}]
					},
					name : null,
				});
			},
			removeRowTableDinamis(key_table_dinamis, key_deleted){
				let self = this;
				var sub_table_dinamis = self.row_konfig_lanjutan.table_dinamis[key_table_dinamis];
				sub_table_dinamis.meta.splice(key_deleted,  1)
			},
			addRowSubMetaTable(key_table_dinamis, key_sub_meta){
				let self = this;
				var sub_meta = self.row_konfig_lanjutan.table_dinamis[key_table_dinamis].meta[key_sub_meta].sub_meta.select_static;
				sub_meta.push({
					pilihan : null,
				});
			},
			removeRowSubMetaTable(key_table_dinamis, key_sub_meta, key_deleted){
				let self = this;
				var sub_meta = self.row_konfig_lanjutan.table_dinamis[key_table_dinamis].meta[key_sub_meta].sub_meta.select_static;
				sub_meta.splice(key_deleted,  1)
			},
			StoreDataKonfigLanjutan(){
				let self = this;
				Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				axios.post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/store-konfigurasi-form-lanjutan", {
						perizinan_sub_id: self.data_perizinan.mps_id,
						row_konfig_lanjutan: JSON.stringify(self.row_konfig_lanjutan)
					},
					{headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.access_token,
					}
				})
				.then(function (response) {
					self.is_lanjut = response.data.data.lanjut;
					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: response.data.data.message,
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
							self.$router.push({ name: "all-sub-perizinan" });
						}
					});
				}).catch(e => {
					self.axiosCatchError = e.response.data.data
					Swal.close();
				})
			},
		}
	};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<b-form class="p-2" @submit.prevent="StoreDataKonfig">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<div class="card-title">Semua Data Konfigurasi Formulir Permohonan Persyaratan Sub Perizinan</div>
								</div>
								<div class="col-md-6">
									<div class="text-end">

									</div>
								</div>
								<div class="col-md-12">
									<div v-if="axiosCatchError">
										<div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
										{{axiosCatchError}}
										</div>
										<div class="alert alert-danger" role="alert" v-else>
											<div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
												<div v-for="(allErrors, idx) in errorArray" :key="idx">
													<span class="text-danger">{{ allErrors[0]}} </span>
												</div>
											</div>
										</div>
									</div>
									<div class="alert alert-info" role="alert">
										<table class="table table-sm table-borderles">
											<tbody>
												<tr>
													<th>Jenis Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.perizinan?.perizinanjenis?.mpj_nama">{{data_perizinan.perizinan.perizinanjenis.mpj_nama}}</span>
													</td>
												</tr>
												<tr>
													<th>Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.perizinan?.mp_nama">{{data_perizinan.perizinan.mp_nama}}</span>
													</td>
												</tr>
												<tr>
													<th>Sub Perizinan</th>
													<th> : </th>
													<td>
														<span v-if="data_perizinan?.mps_nama">{{data_perizinan.mps_nama}}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<table class="table table-bordered table-sm">
										<thead class="table-info">		
											<tr>
												<th>No</th>
												<th>Label Field</th>
												<th>Tipe Field</th>
												<th>Nama Field</th>
												<th>Required?</th>
												<th>Urutan</th>
												<th>Catatan</th>
												<th>
													<div class="btn btn-success btn-sm" v-on:click="addRowKonfig()"><i class="bx bx-plus"></i></div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row_konfig_value, row_konfig_key) in row_konfig" :key="row_konfig_key" >
												<td>{{row_konfig_key+1}}</td>
												<td>
													<input type="text" class="form-control" v-model="row_konfig_value.mkp_label_field" />
												</td>
												<td>
													<v-select 
														:options="select_option_field" 
														label="label"
														placeholder="Pilih Tipe Field"
														v-model="row_konfig_value.mkp_tipe_field"
													></v-select>
												</td>
												<td>
													<input type="text" class="form-control" v-model="row_konfig_value.mkp_nama_field" />
												</td>
												<td>
													<b-form-checkbox v-model="row_konfig_value.mkp_is_required" name="mspk_is_tte" value="required"></b-form-checkbox>
												</td>
												<td>
													<input type="text" class="form-control" v-model="row_konfig_value.mkp_urutan"  />
												</td>
												<td>
													<textarea class="form-control" v-model="row_konfig_value.mkp_catatan" ></textarea>
												</td>
												<td>
													<div class="btn btn-danger btn-sm" v-on:click="removeKonfig(row_konfig_key)"><i class="bx bx-minus"></i></div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<div class="text-end">
								<b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
							</div>
						</div>
					</b-form>
				</div>
				<div class="card" v-if="is_lanjut">
					<b-form class="p-2" @submit.prevent="StoreDataKonfigLanjutan">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<div class="card-title">Konfgurasi Lanjutan</div>
								</div>
								<div class="col-md-6">
									<div class="text-end">

									</div>
								</div>
								<div class="col-md-12" v-if="row_konfig_lanjutan.select_dinamis.length >= 1">
									
									<table class="table table-sm table-bordered">
										<caption><i class="fa fa-cogs"></i> Pengaturan Select Option Dinamis</caption>
										<thead class="bg-info">
											<tr>
												<th width="200">Label Field</th>
												<th width="200">Asal Table</th>
												<th width="200">Pilihan</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(select_dinamis_val, select_dinamis_key) in row_konfig_lanjutan.select_dinamis" :key="select_dinamis_key">
												<td>
													{{ select_dinamis_val.mkp_label_field }}
												</td>
												<td>
													<v-select 
														:options="list_table" 
														label="table"
														placeholder="Pilih Tabel"
														v-model="select_dinamis_val.table_join"
													></v-select>
												</td>
												<td>
													<v-select 
														:options="select_dinamis_val?.table_join?.structure" 
														placeholder="Pilih Pilihan Tabel"
														:disabled="!select_dinamis_val?.table_join" 
														v-model="select_dinamis_val.table_join_pilihan"
													></v-select>
												</td>
											</tr>
										</tbody>
									</table>
									
								</div>
								<div class="col-md-12" v-if="row_konfig_lanjutan.select_statis.length >= 1">
									<table class="table table-bordered table-sm">
										<caption><i class="fa fa-cogs"></i> Pengaturan Select Option Statis</caption>
										<thead class="bg-info">
											<tr>
												<th width="200">Label Field</th>
												<th>Pilihan</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(select_statis_val, select_statis_key) in row_konfig_lanjutan.select_statis" :key="select_statis_key">
												<td>
													{{ select_statis_val.mkp_label_field }}
												</td>
												<td>
													<table class="table table-bordered table-sm">
														<thead class="bg-dark text-white">
															<tr>
																<th>Pilihan Select</th>
																
																<th><div class="btn btn-success btn-sm" v-on:click="addRowSelectStatic(select_statis_key)"><i class="fa fa-plus"></i></div></th>
															</tr>
														</thead>
														<tbody>
															<tr  v-for="(sub_select_statis_val, sub_select_statis_key) in select_statis_val.meta" :key="sub_select_statis_key">
																<td>
																	<input type="text" class="form-control" v-model="sub_select_statis_val.pilihan">
																</td>
																<td><div class="btn btn-danger btn-sm" v-on:click="removeRowSelectStatic(select_statis_key, sub_select_statis_key)"><i class="fa fa-minus"></i></div></td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>

										</tbody>
									</table>
									
								</div>
								<div class="col-md-12" v-if="row_konfig_lanjutan.table_dinamis.length >= 1">
									<table class="table table-bordered table-sm">
										<caption><i class="fa fa-cogs"></i> Pengaturan Table Dinamis</caption>
										<thead class="bg-info">
											<tr>
												<th width="200">Label Field</th>
												<th>Table Dinamis</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(table_dinamis_val, table_dinamis_key) in row_konfig_lanjutan.table_dinamis" :key="table_dinamis_key">
												<td>
													{{ table_dinamis_val.mkp_label_field }}
												</td>
												<td>
													<table class="table table-bordered table-sm">
														<thead class="bg-dark text-white">
															<tr>
																<th>Label Field</th>
																<th>Tipe Field</th>
																<th>Name Field</th>
																<th>
																	<div class="btn btn-success btn-sm" v-on:click="addRowTableDinamis(table_dinamis_key)"><i class="fa fa-plus"></i></div>
																</th>
															</tr>
														</thead>
														<tbody>
															<tr  v-for="(sub_table_dinamis_val, sub_table_dinamis_key) in table_dinamis_val.meta" :key="sub_table_dinamis_key">
																<td>
																	<input type="text" class="form-control" v-model="sub_table_dinamis_val.label">
																</td>
																<td>
																	<v-select 
																		:options="select_option_field_sub" 
																		label="label"
																		placeholder="Pilih Tipe Field"
																		v-model="sub_table_dinamis_val.tipe_field"
																	></v-select>
																	<div class="row mt-2" v-if="sub_table_dinamis_val.tipe_field == 'Select Dynamic'">
																		<div class="col-md-12 mt-2">
																			<v-select 
																				:options="list_table" 
																				label="table"
																				placeholder="Pilih Tabel"
																				v-model="sub_table_dinamis_val.sub_meta.table"
																			></v-select>
																		</div>
																		<div class="col-md-12 mt-2">
																			<v-select 
																				:options="sub_table_dinamis_val?.sub_meta?.table?.structure" 
																				placeholder="Pilih Pilihan Tabel"
																				:disabled="!sub_table_dinamis_val.sub_meta.table" 
																				v-model="sub_table_dinamis_val.sub_meta.pilihan"
																			></v-select>
																		</div>
																	</div>
																	<div class="row mt-2" v-if="sub_table_dinamis_val.tipe_field == 'Select Static'">
																		<div class="col-md-12 mt-2">

																			<div class="row mb-1" v-for="(sm_val, sm_key) in sub_table_dinamis_val?.sub_meta?.select_static" :key="sm_key">
																				<div class="col-md-10">
																					<input type="text" v-model="sm_val.pilihan" class="form-control" />
																				</div>
																				<div class="col-md-2">
																					<div class="btn btn-success btn-sm" v-if="sm_key == 0" v-on:click="addRowSubMetaTable(table_dinamis_key, sub_table_dinamis_key)"><i class="fa fa-plus"></i></div>
																					<div class="btn btn-danger btn-sm" v-else v-on:click="removeRowSubMetaTable(table_dinamis_key, sub_table_dinamis_key, sm_key)"><i class="fa fa-minus"></i></div>
																				</div>
																			</div>
																		</div>

																	</div>
																</td>
																<td>
																	<input type="text" class="form-control" v-model="sub_table_dinamis_val.name">
																</td>
																<td>
																	<div class="btn btn-danger btn-sm" v-on:click="removeRowTableDinamis(table_dinamis_key, sub_table_dinamis_key)"><i class="fa fa-minus"></i></div>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>

										</tbody>
									</table>
									
								</div>

							</div>
						</div>
						<div class="card-footer">
							<div class="text-end">
								<b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</div>
	</Layout>
</template>